import React, { useContext } from "react";
import OrdersCardDesktop from "./OrdersCardDesktop";
import { GlobalContext } from "../../../context/GlobalContext";
import useTranslation from "../../../hooks/translations";

const OrdersCard = ({ data }: {data: any}) => {
    const { theme } =
    useContext(GlobalContext);
    const filteredItems = data?.items
        ? data.items.filter((item: any) => item.particulars !== "Service Charges")
        : [];
    
    const {t} = useTranslation()

    const amountsData = [
        {
            name: t("subTotal"),
            value:
                data?.totalAmount && data?.currency
                    ? `${data.totalAmount
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")} ${
                          data.currency === "XAF" ? "FCFA" : data.currency
                      }`
                    : null,
        },
        {
            name: t("serviceCharge"),
            value:
                data?.appliedServiceCharge && data?.currency
                    ? `${data.appliedServiceCharge
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")} ${
                          data.currency === "XAF" ? "FCFA" : data.currency
                      }`
                    : null,
        },
        {
            name: t("totalAmount"),
            lastItem: true,
            value:
                data?.amountPaid && data?.currency
                    ? `${data.amountPaid
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")} ${
                          data.currency === "XAF" ? "FCFA" : data.currency
                      }`
                    : null,
        },
    ];
    
    return (
        <OrdersCardDesktop
            data={data}
            items={filteredItems}
            amountsData={amountsData}
            theme={theme}
        />
    )
};

export default OrdersCard;
