import React from "react";
import useUI from "../../../../hooks/useUI";
import { FormControl, Grid, Typography } from "@mui/material";
import FormTextField from "../../../../components/FormTextField";
import { PhoneNumberInput } from "../../../../components/PhoneNumberInput";
import { MOBILE_MONEY } from "../../../../constants/data";
import useTranslation from "../../../../hooks/translations";

interface PhoneSectionProps {
  formik: any;
  paymentProviderName: string;
  disabled: boolean;
  providerType: any
}

const PhoneSection: React.FC<PhoneSectionProps> = ({ formik, paymentProviderName, disabled, providerType }) => {
  const {t} = useTranslation()
  
  return (
    <Grid item>
      <Grid item>
        {paymentProviderName === "safimoney" ? (
          <FormControl className="form-control" fullWidth >
            <Typography
              component="legend"
              className={"fieldTitleSecondary"}
            >
              Wallet ID
            </Typography>
            <Grid item className={"inputWrapper"}>
              <FormTextField
                id="walletId"
                name="walletId"
                label="Wallet ID"
                variant="outlined"
                className={"input MuiInputBase-input"}
                formik={formik}
              />
            </Grid>
          </FormControl>
        ) : (
          <FormControl className="form-control" fullWidth >
            <Typography
              component="legend"
              className={"fieldTitleSecondary"}
            >
              {providerType === MOBILE_MONEY ? t("mobileMoneyNumber") : t("phoneNumber")} <span style={{fontSize: 14, color: "red"}}>*</span>
            </Typography>
            <Grid item className={"inputWrapper"}>
              <PhoneNumberInput
                id="notification.mobileNumber"
                name="notification.mobileNumber"
                variant="outlined"
                formik={formik}
                disabled={!disabled}
                disableDropdown={providerType === MOBILE_MONEY ? true: false}
              />
            </Grid>
          </FormControl>
        )}
      </Grid>
    </Grid>
  );
};

export default PhoneSection;
