import React from "react";
import { FormControl, Grid, Typography, Box, TextField } from "@mui/material";
import FormAutoComplete from "../../../../components/FormAutoComplete";
import { countryList } from "../../../../constants";
import useTranslation from "../../../../hooks/translations";
interface CountrySectionProps {
  formik: any;
  onChangeCountry: (country: any) => void;
  selectedOption: any;
}

const CountrySection: React.FC<CountrySectionProps> = ({ formik, onChangeCountry, selectedOption }) => {
  const {t} = useTranslation()
  return (
    <Box>
      <Grid item>
        <FormControl
          className={"fieldset form-control"}
          component="fieldset"
          error
          fullWidth
        >
          <Typography
            component="legend"
            className={"fieldTitleSecondary"}
          >
            {t("country")} <span style={{fontSize: 14, color: "red"}}>*</span>
          </Typography>
          <FormAutoComplete
            fullWidth
            resource="country"
            options={countryList}
            defaultValue={countryList.find((item) => item?.name === "Cameroon")}
            disableClearable
            disabled={true}
            variant={"outlined"}
            type={"country"}
            label={""}
            onChange={(country) => onChangeCountry(country)}
            selectedOption={selectedOption}
            size="small"
            loading={false}
            readOnly={false}
            dataTestId="country-autocomplete"
            renderInput={(params) => <TextField {...params} />}
          />
        </FormControl>

        {
          <Typography
            pb={2}
            pl={2}
            style={{
              color: "#d32f2f",
              fontSize: "0.75rem",
            }}
          >
            {formik.errors.country}
          </Typography>
        }
      </Grid>
    </Box>
  );
};

export default CountrySection;
