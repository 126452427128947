export default {
    paymentButton: "Pay",
    paymentPhoneError: "Your mobile number is required",
    paymentNameError: "Your name is required",
    paymentMethodError: "Payment option is required",
    paymentTerms: "You must agree to the terms and conditions",
    thanksPageTitle1: "Thank you for choosing",
    thanksPageTitle2: "for your payment !",
    providerTitle: "Provider",
    instructionsTitle: "Instructions",
    thanksPageSubTitle1: "Your payment is being processed.",
    thanksPageSubTitle2: "You shall be notified once it has been completed.",
    thanksPageText:
        "If you paid via Mobile Money, there is a small chance that you may not receive the prompt from your Mobile Money Provider. In that case, you can manually \n" +
        "trigger the payment.",
    orangeInstructions:
        "Dial #150*50# to validate your withdrawal \n" +
        "in case you did not receive prompt.",
    mtnInstructions:
        "Dial *126# to validate your withdrawal in case you did not receive prompt.",
    euInstructions:
        "Just login in your Express Union Mobile App click on Authorize \n" +
        "and approve the withdrawal in case you did not receive SMS notification from Express Union.",
    yoomeeInstructions:
        "Login into your Yoomee Money Mobile App, Go to Cashout click on Incoming Cashout Request and Accept the withdrawal",
    backToShopButton: "Back to",
    backToMerchantSite: "Back to merchant site",
    successfulTransaction: "Successful transaction!",
    wasSuccessfullyTreated: "was successfully treated.",
    transactionFailed: "Transaction failed!",
    hasFailed: "has failed.",
    yourPaymentId: "Your payment",
    redirectMessage1: "You will be redirected in",
    redirectMessage2: " seconds to merchant’s site.",
    errorPageTitle: "Error.",
    errorPageSubtitle: "An error occurred during the corresponding request.",
    mobileError: "Please provide a valid mobile money number.",
    paymentError:
        "Cannot process payment: Select a payment method and provide a mobile number.",
    generalError: "General error during transaction processing.",
    contactSupport: "Contact support team ",
    backButton: "Back",
    walletIdLength: "Must be exactly 16 symbols",
    walletIdError: "Wallet ID is required",
    wrongMobileNumber: "Please enter a valid mobile money number.",
    wrongPhoneNumber: "Please enter a valid phone number",
    paypalRedirection: "You will be redirected to the PayPal website if you choose to pay with PayPal, but first, you must agree to the terms and conditions to activate the button.",
    selectProvider: "Select your payment method",
    flocashRedirection: "You will be redirected to a secured form where you will enter your credit card details, but first, you must agree to the terms and conditions to activate the button.",
    paymentFailed: "Payment failed",
    close: "close",
    invalidEmailError: "Please enter a valid email",
    emailRequiredError: "Email address is required",
    maxNumberOfCharacters: "Maximum number of characters exeeded",
    //new translations
    verifyWalletBalance: "To avoid any payment failures, make sure you have enough money in your account.",
    shoppingCard: "Shopping Card",
    doubleCheckSelectedItems: "Double-check your selected items, quantities, and total price. Once you're ready, proceed to provide your payment details to complete your order securely.",
    product: "Product",
    quantity: "Quantity",
    unitPrice: "Unit Price",
    totalPrice: "Total Price/ FCFA",
    priceMobile: "Price",
    productMobile: "Items",
    subTotal: "Sub total",
    serviceCharge: "Service charge",
    totalAmount: "Total amount",
    serviceTemporarilyUnavailable: "Service Temporarily Unavailable",
    country: "Country",
    email: "Email",
    phoneNumber: "Phone number",
    mobileMoneyNumber: "Mobile money number",
    nameAndSurname: "Name and surname",
    enterNameAndSurname: "Enter name and surname",
    enterEmailAdrress: "Enter your email address",
    provider: "Provider",
    paymentMethod: "Payment Method",
    change: "Change",
    paypal: "Paypal",
    mobileMoney: "Mobile Money",
    creditCard: "Credit Card",
    english: "English",
    french: "French",
    agreeTo: "Agree to",
    termsAndCondition: "terms and conditions",
    proceed: "Proceed",
    allRightsReserved: "All rights reserved",
    securedPlatform: "This platform is securely powered by Maviance PLC. For more details, please refer to our ",
    privacyPolicy: "Privacy Policy",
    termsOfService: "Terms of Service",
    and: "and",
    paymentFailedWithId: "Your payment ID: {0} has failed.",
    exit: "Exit",
    return: "Return", 
    awaitingPayment: "Awaiting payment",
    notififcationToWithdraw: "You will receive a notification on your phone from Maviance to withdraw",
    dialToApprove: " Dial {0} on your mobile phone to validate your withdrawal in case you do not receive a notification",
    paymentSuccessful: "Successful payment",
    successMessageWithId: "Your payment ID: {0} has been successfully processed. Your account has been debited with the amount of"
};
