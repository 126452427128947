import * as React from 'react';
import { Box, Button, Typography, Modal } from '@mui/material';
import SuccessIcon from "../../assets/images/SuccessIcon.svg";
import "./style.css";
import useTranslation from '../../hooks/translations';

interface PaymentSuccessModalProps {
  amount: number;
  transactionId: string;
  isOpen: boolean;
  onClose: () => void;
  returnUrl: string;
}

const PaymentSuccessModal: React.FC<PaymentSuccessModalProps> = ({
  amount, transactionId, isOpen, onClose, returnUrl
}) => {
  const returnToMerchantPage = (url: string) => {
    window.location.replace(url)
  }
  const {t, translationObject} = useTranslation()

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={"box"}>
        <Box className={"imageBox"}>
          <img src={SuccessIcon} alt="success" />
        </Box>
        <Typography variant="h4" component="h2" gutterBottom className={"centerText"}>
          {t("paymentSuccessful")}
        </Typography>
        <Typography variant="body1" component="p" gutterBottom sx={{ color: "#667085" }} className={"centerText"}>
          {translationObject.formatString(t("successMessageWithId"), <span className={"span"}>{transactionId}</span>)}
        </Typography>
        <Typography variant="h4" component="h2" gutterBottom sx={{ fontWeight: 500 }} className={"centerText"}>
          {amount} FCFA
        </Typography>
        {/* <Button variant="contained" onClick={onClose} sx={{color: "white", mt: 2}} className={"button"}>
          Download Proof of payment
        </Button> */}
        <Button
          variant="contained"
          onClick={() => returnToMerchantPage(returnUrl)}
          sx={{ color: "white", mt: 2 }}
          className={"button"}
        >
          {t("return")}
        </Button>
      </Box>
    </Modal>
  );
};

export default PaymentSuccessModal;