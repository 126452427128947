import React from 'react';
import { makeStyles } from "@mui/styles";
import { Grid, GridProps } from "@mui/material";

interface GridWrapperProps {
    direction?: "row" | "column";
    alignItems?: "flex-start" | "center" | "flex-end" | "stretch" | "baseline";
    justifyContent?: boolean;
    children: React.ReactNode
}

const useStyles = makeStyles((theme: { spacing: (factor: number) => number }) => ({
    root: {
        margin: "0 auto",
        justifyContent: "center",
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        width: "100%",
    },
}));


const GridWrapper: React.FC<GridWrapperProps> = ({ children, direction, alignItems, justifyContent }) => {
    const classes = useStyles();
    return (
        <Grid container direction={direction} className={classes.root} alignItems={alignItems} justifyContent={justifyContent ? "center" : undefined}>
            {children}
        </Grid>
    );
};

export default GridWrapper;