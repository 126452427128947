import React from "react";
import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const ColorButton = styled(Button)(({ theme }) => ({
    width: "fit-content",
    color: theme.palette.primary.main,
    textTransform: "none",
    fontWeight: "bold",
    fontSize: 16,
    padding: theme.spacing(1.5, 4),
    marginRight: theme.spacing(3.5),
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.default,
    "&:hover": {
        backgroundColor: "#E7FCFF",
    },
    [theme.breakpoints.down("md")]: {
        width: "100%",
        height: 45,
        marginRight: theme.spacing(0),
    },
}));

interface ButtonBorderStyledProps extends ButtonProps {
    label: string;
    icon?: React.ReactNode;
}

const ButtonBorderStyled: React.FC<ButtonBorderStyledProps> = ({
    className,
    type,
    style,
    label,
    icon,
    onClick = () => {},
    ...props
}) => {
    return (
        <ColorButton
            className={className}
            variant={"contained"}
            style={style}
            type={type}
            onClick={onClick}
            startIcon={icon}
            {...props}
        >
            {label}
        </ColorButton>
    );
};

export default ButtonBorderStyled;
