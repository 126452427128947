import React from 'react';

import {Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography} from "@mui/material";
import { trimText } from '../../helpers/trimText';

import "./cardStyle.css"

interface Row {
  [key: string]: any;
}

interface CardGridProps {
  columns: any[];
  rows: Row[];
  currency: string;
}

const CardGrid: React.FC<CardGridProps> = ({ columns, rows, currency }) => {
  return (
    <Table className={"cardGridRoot"}>
        <TableHead>
          <TableRow className={"tableHeader"}>
            {columns.map((column) => (
              <TableCell key={column.id} align={column.align}>
                <Typography variant='body1' align='right'>{column.label}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      
      <TableBody>
        {rows?.map((row, index) => (
          <TableRow
            className={"row"}
            key={row?.itemId}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row" className={"tableCell"}>
              <Tooltip title={row?.particulars} className={"tableCell"}>
                <span>{index + 1}</span>
              </Tooltip>
              
            </TableCell>
            <TableCell align="left">
              <Tooltip title={row?.particulars} className={"tableCell"}>
                <span>{trimText(row?.particulars)}</span>
              </Tooltip>
            </TableCell>
            <TableCell align="center" style={{ textAlign: "center" }} className={"tableCell"}>
            <Tooltip title={row?.particulars} className={"tableCell"}>
                <span>{trimText(row?.quantity)}</span>
              </Tooltip>
            </TableCell>
            <TableCell align="center" className={"tableCell"}>
              <Tooltip title={row?.particulars} className={"tableCell"}>
                <span>{row?.unitCost.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}{" "}</span>
              </Tooltip>
            </TableCell>
            <TableCell align="center" className={"tableCell"}>
              <Tooltip title={row?.particulars} className={"tableCell"}>
                <span>{row?.subTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}{" "}</span>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default CardGrid;
