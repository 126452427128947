import * as React from 'react';
import { Backdrop as MUIBackdrop } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const Backdrop = ({open}: {open: boolean})  => {

  return (
    <div>
      <MUIBackdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={open}
      >
        <CircularProgress color="inherit" />
      </MUIBackdrop>
    </div>
  );
}

export default Backdrop;