export default {
    paymentButton: "Payer",
    paymentPhoneError: "Votre numéro de portable est obligatoire",
    paymentNameError: "Votre nom est obligatoire",
    paymentMethodError: "Une option de paiement est obligatoire",
    paymentTerms: "Votre accord avec les termes et conditions est requis",
    thanksPageTitle1: "Merci d’avoir choisi",
    thanksPageTitle2: " comme mode de paiement !",
    providerTitle: "Fournisseur",
    instructionsTitle: "Instructions",
    thanksPageSubTitle1: "Votre paiement est en cours de traitement.",
    thanksPageSubTitle2: " Vous serez informé une fois la transaction terminée",
    thanksPageText:
        "Si vous avez payé via Mobile Money, il y a une petite chance \n" +
        "que vous ne receviez pas\n" +
        "la notification SMS de votre fournisseur. \n" +
        "Dans ce cas, vous pouvez manuellement\n" +
        "déclencher le paiement.",
    orangeInstructions:
        "Composez le 150*50# pour valider votre retrait au cas où vous n'auriez pas reçu de demande.",
    mtnInstructions:
        "Composez *126# pour valider votre retrait \n" +
        "au cas où vous n'auriez  pas reçu de demande.",
    euInstructions:
        "Il vous suffit de vous connecter à votre application mobile Express Union et de cliquer sur Autoriser et approuver \n" +
        "le retrait si vous n'avez pas reçu de notification par SMS\n" +
        "de la part d'Express Union.",
    yoomeeInstructions:
        "Connectez-vous à votre application mobile Yoomee Money, accédez à Cashout, cliquez sur Incoming Cashout Request et acceptez le retrait",
    backToShopButton: "Retour vers",
    backToMerchantSite: "Retour vers site du marchand",
    successfulTransaction: "Transaction réussi !",
    yourPaymentId: "Votre paiement",
    wasSuccessfullyTreated: "a été traité avec succès.",
    transactionFailed: "Transaction echoué !",
    hasFailed: "a echoué.",
    redirectMessage1: "Vous serez redirigé  ",
    redirectMessage2: " secondes vers le site marchand.",
    errorPageTitle: "Erreur.",
    errorPageSubtitle: "Une erreur s’est produite lors de la requête en cours.",
    mobileError: "Veuillez fournir un numéro mobile money valide.",
    paymentError:
        "Impossible de traiter le paiement : Sélectionnez un mode de paiement et fournissez un numéro mobile money valide.",
    generalError: "Échec  générale pendant le traitement de la transaction.",
    contactSupport: "Contacter l’équipe de support ",
    backButton: "Retour",
    walletIdLength: "Doit être exactement 16 symboles ",
    walletIdError: "L'identifiant de portefeuille est requis",
    wrongMobileNumber: "Veuillez entrer un numéro mobile money valide.",
    wrongPhoneNumber: "Veuillez entrer un numéro de téléphone valide",
    paypalRedirection: "Vous serez redirigé vers le site PayPal si vous choisissez de payer avec PayPal, mais vous devez d'abord accepter les termes et conditions pour activer le bouton.",
    selectProvider: "Séléctionner un mode de paiement",
    flocashRedirection: "Vous serez redirigé vers un formulaire sécurisé où vous saisirez les détails de votre carte de crédit, mais vous devez d'abord accepter les termes et conditions pour activer le bouton.",
    paymentFailed: "Échec du paiement",
    close: "fermer",
    invalidEmailError: "Veuillez entrer une adresse e-mail valide",
    emailRequiredError: "L'adresse e-mail est requise",
    maxNumberOfCharacters: "Nombre maximum de caractères dépassé",

    // new translation
    verifyWalletBalance: "Pour éviter les échecs de paiement, assurez-vous d'avoir suffisamment d'argent sur votre compte.",
    shoppingCard: "Panier d'achat",
    doubleCheckSelectedItems: "Vérifiez vos articles sélectionnés, les quantités et le prix total. Une fois prêt, entrez vos informations de paiement pour finaliser votre commande en toute sécurité.",
    product: "Produit",
    quantity: "Quantité",
    unitPrice: "Prix unitaire",
    totalPrice: "Prix total/ FCFA",
    priceMobile: "Prix",
    productMobile: "Atc",
    subTotal: "Sous total",
    serviceCharge: "Frais de service",
    totalAmount: "Montant total",
    serviceTemporarilyUnavailable: "Service temporairement indisponible",
    country: "Pays",
    email: "Email",
    phoneNumber: "Numéro de téléphone",
    mobileMoneyNumber: "Numéro de téléphone MoMo",
    nameAndSurname: "Nom et prénom",
    enterNameAndSurname: "Entrez votre Nom et prénom",
    enterEmailAdrress: "Entre votre adresse email",
    provider: "Fournisseur",
    paymentMethod: "Méthode de paiement",
    change: "Changez",
    paypal: "Paypal",
    mobileMoney: "Moyen de paiement mobile",
    creditCard: "Carte de crédit",
    english: "Anglais",
    french: "Français",
    agreeTo: "J'accepte les ",
    termsAndCondition: "termes et conditions",
    proceed: "procéder",
    allRightsReserved: "Tous droits réservés",
    securedPlatform: "Cette plateforme est sécurisée  par Maviance PLC. Pour plus de détails, veuillez consulter notre",
    privacyPolicy: "Politique de confidentialité ",
    termsOfService: "Conditions d'utilisation",
    and: "et nos",
    paymentFailedWithId: "Votre ID de paiement: {0} a échoué.",
    exit: "sortie",
    return: "retour",
    awaitingPayment: "En attente de paiement",
    notififcationToWithdraw: "Vous recevrez une notification de Maviance sur votre téléphone pour retirer",
    dialToApprove: "Composez {0} sur votre téléphone mobile pour confirmer votre retrait si vous ne recevez pas de notification.",
    paymentSuccessful: "Paiement réussi",
    successMessageWithId: "Votre ID de paiement : {0} a été traité avec succès. Votre compte a été débité du montant de"
};
