import axios from "axios";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import { GlobalContext } from "../../../context/GlobalContext";
import useTranslation from "../../../hooks/translations";
import Forms from "./Forms";
import PaymentSusccessModal from "../../../components/Modals/PaymentSuccessModal";
import {
  FAILED,
  PROCESSING,
  SUCCESS,
} from "../../../constants/transactionStatus";
import PaymentFailedModal from "../../../components/Modals/PaymentFailModal";
import PaymentProcessingModal from "../../../components/Modals/PaymentProcessingModal";
import { CREDIT_CARD, MOBILE_MONEY, PAYPAL } from "../../../constants/data";
import { Snackbar, SnackbarContent } from "@mui/material";
import palette from "../../../styles/palette";
import { Backdrop } from "../../../components";
interface Merchant {
  id: string;
  // add other properties of Merchant if needed
}

const FormDetails = ({ payment, order, merchant }: { payment: any; order: any; merchant: Merchant }) => {
  const {
    setErrorRequest,
    setPaymentProviderName,
    paymentProviderName,
    theme,
    searchResults,
  } = useContext(GlobalContext);
  const { t } = useTranslation();
  const [initFormData, setInitFormData] = useState<any>({});
  const [checked, setChecked] = useState(false);
  const [pricingId, setPricingId] = useState({});
  const [paymentProviderId, setPaymentProviderId] = useState({});
  const [radioChecked, setRadioChecked] = useState(false);
  const [providerType, setProviderType] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalState, setModalState] = useState<keyof typeof MODAL_COMPONENTS | "">("");
  const [backdropOpen, setBackDropOpen] = useState(false)
  const [paymentError, setPaymentError] = useState(false)

  const FORM_VALIDATION = yup.object({
    notification: yup
      .object()
      .when("paymentProviderId.uuid", {
        is: "u123a1b8490a4ea1335eed85a799a6fb", // MTN
        then: () =>
          yup.object({
            customerName: yup.string().max(20, t("maxNumberOfCharacters")).required(t("paymentNameError")),
            mobileNumber: yup
              .string()
              .required(t("paymentPhoneError"))
              .matches(
                /^(237|00237|\+237)?((650|651|652|653|654|680|681|682|683|684)[0-9]{6}$|(67[0-9]{7})$)/,
                t("wrongMobileNumber")
              ),
          }),
      })
      .when("paymentProviderId.uuid", {
        is: "b166771794fa2b1099212d5ff107e22a", // ORANGE
        then: () =>
          yup.object({
            customerName: yup.string().max(20, t("maxNumberOfCharacters")).required(t("paymentNameError")),
            mobileNumber: yup
              .string()
              .required(t("paymentPhoneError"))
              .matches(
                /^(237)?((655|656|657|658|659|686|687|688|689|640)[0-9]{6}$|(69[0-9]{7})$)/,
                t("wrongMobileNumber")
              ),
          }),
      })
      .when("paymentProviderId.uuid", {
        is: "o911y2q8hg0a4ea19o52ed15h552a6hq", // YOOMEE
        then: () =>
          yup.object({
            customerName: yup.string().max(20, t("maxNumberOfCharacters")).required(t("paymentNameError")),
            mobileNumber: yup
              .string()
              .required(t("paymentPhoneError"))
              .matches(/^(237)?((24)[0-9]{7}$)/, t("wrongMobileNumber")),
          }),
      })
      .when("paymentProviderId.uuid", {
        is: "n123m1b8490a4ea1335eed85b799a6fb", // EXPRESS UNION
        then: () =>
          yup.object({
            customerName: yup.string().max(20, t("maxNumberOfCharacters")).required(t("paymentNameError")),
            mobileNumber: yup
              .string()
              .required(t("paymentPhoneError"))
              .matches(/^\+?[1-9]\d{1,14}$/, t("wrongMobileNumber")),
          }),
      })
      .when("providerType", { //CREDIT CARD
        is: "Card",
        then: () =>
          yup.object({
            customerName: yup.string().max(20, t("maxNumberOfCharacters")).required(t("paymentNameError")),
            mobileNumber: yup
              .string()
              .required(t("paymentPhoneError"))
              .matches(
                /^(237|00237|\+237)?(6[0-9]{8})$/,
                t("wrongPhoneNumber")
              ),
            email: yup
              .string()
              .email(t("invalidEmailError"))
              .max(50, t("maxNumberOfCharacters"))
              .required(t("emailRequiredError")),
          }),
      }),
  
    providerType: yup.string().required(t("paymentMethodError")),
    
    // Make paymentProviderId required only when providerType is Mobile Money
    paymentProviderId: yup.object().when("providerType", {
      is: "Mobile Money",
      then: () =>
        yup.object({
          uuid: yup.string().required(t("paymentMethodError")),
        }),
      otherwise: () => yup.object(),
    }),
  
    // Make walletId required only for safimoney
    walletId: yup
      .string()
      .nullable()
      .when([], {
        is: () => paymentProviderName === "safimoney",
        then: () =>
          yup
            .string()
            .min(16, t("walletIdLength"))
            .max(16, t("walletIdLength"))
            .required(t("walletIdError")),
        otherwise: () => yup.string().nullable(),
      }),
  });

  useEffect(() => {
    let INITIAL_FORM_STATE = {
      id: order?.id ? order?.id : "",
      notification: {
        customerName: order?.customerName ? order?.customerName : "",
        email: order?.email ? order?.email : "",
        mobileNumber: order?.phoneNumber
          ? order?.phoneNumber.length <= 9
            ? `+237${order?.phoneNumber}`
            : order?.phoneNumber
          : "+237",
      },
      providerType: providerType ? providerType : null,
      paymentProviderId: paymentProviderId ? paymentProviderId : {},
      pricingId: pricingId ? pricingId : "",
      totalAmount: order?.amountPaid || null,
      merchantId: merchant?.id ? merchant?.id : null,
      otp: null,
      walletId: null,
    };
    setInitFormData(INITIAL_FORM_STATE);
  }, [
    providerType,
    payment,
    order,
    pricingId,
    paymentProviderId,
    merchant?.id,
  ]);

  const handleChecked = () => {
    setChecked(!checked);
  };

  const getPaypalUrl = (values: any) =>
    `/payment/api/order/paypal/${searchResults?.orderPlacement?.id?.uuid}/init/${values?.paymentProviderId?.uuid}`;
  const getFlowcashUrl = (values: any) =>
        `/payment/api/order/flocash/${searchResults?.orderPlacement?.id?.uuid}/init/${values?.paymentProviderId?.uuid}`;
  const getMobileUrl = (values: any) =>
    `/payment/api/order/${values?.id?.uuid}/payWith/${values?.paymentProviderId?.uuid}`;

  const isPaypal = providerType === PAYPAL;
  const isCard = providerType === CREDIT_CARD;
  const isMobileMoney = providerType === MOBILE_MONEY;

  const errorOperation: any = {
    [MOBILE_MONEY]: () => {
      setModalOpen(true);
      setModalState(FAILED);
    },
    [PAYPAL]: () => {
      setBackDropOpen(false);
      setPaymentError(true);
    },
    [CREDIT_CARD]: () => {
      setBackDropOpen(false);
      setPaymentError(true);
    }
  }

  const processingOperation: any = {
    [MOBILE_MONEY]: () => {
      setModalOpen(true);
      setModalState(PROCESSING)
    },
    [PAYPAL]: () => {
      setBackDropOpen(true)
    },
    [CREDIT_CARD]: () => {
      setBackDropOpen(true);
    }
  }

  const handleFormSubmit = (values: any) => {
    axios.defaults.withCredentials = true;

    processingOperation[providerType]()
      axios({
        method: isPaypal ? "GET" : "PUT",
        baseURL: process.env.REACT_APP_API_HOST,
        url: isPaypal ? getPaypalUrl(values) : isCard ? getFlowcashUrl(values) : getMobileUrl(values),
        withCredentials: true,
        data: values,
      })
        .then((resp) => {
          // if payment method is different from paypal or credit card, use normal flow
         if(resp.status === 200 && !isPaypal && !isCard) {
            setModalOpen(true);
            setModalState(SUCCESS);
            return 
          } 
          setBackDropOpen(false)
          setChecked(false)
          
          window.location.replace(resp.data);
        })
        .catch((error) => {
          let resp = error?.response?.data;
          setErrorRequest({
            code: resp?.errorCodeNumeric,
            message: resp?.message,
          });
          errorOperation[providerType]();
          setChecked(false)
        });
    
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...initFormData },
    validateOnMount: true,
    validationSchema: FORM_VALIDATION,
    onSubmit: handleFormSubmit,
  });

  const MODAL_COMPONENTS = {
    SUCCESS: PaymentSusccessModal,
    FAILED: PaymentFailedModal,
    PROCESSING: PaymentProcessingModal,
  };

  const CurrentModal = modalState && MODAL_COMPONENTS[modalState];

  return (
    <>
        {CurrentModal && (
          <CurrentModal
            amount={initFormData.totalAmount}
            transactionId={searchResults?.orderPlacement?.id?.uuid}
            isOpen={modalOpen}
            onClose={() => setModalOpen(false)}
            returnUrl={searchResults?.fullReturnUrl}
          />
        )}
        <Backdrop open={backdropOpen} />
        <Snackbar
          autoHideDuration={4000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={paymentError}
          onClose={() => setPaymentError(false)}
        >
          <SnackbarContent style={{
              backgroundColor: palette.red,
              fontSize: '.8rem'
            }}
            message={providerType + " " + t("serviceTemporarilyUnavailable")}
          />
        </Snackbar>
        <Forms
          theme={theme}
          formik={formik}
          payment={payment}
          setPaymentProviderId={setPaymentProviderId}
          setPricingId={setPricingId}
          setPaymentProviderName={setPaymentProviderName}
          radioChecked={radioChecked}
          setRadioChecked={setRadioChecked}
          paymentProviderName={paymentProviderName}
          checked={checked}
          handleChecked={handleChecked}
          providerType={providerType}
          setProviderType={setProviderType}
        />
    </>
  );
};
export default FormDetails;
