import React, {useContext} from "react";
import {Divider, Grid, Link, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import ButtonStyled from "../components/ButtonStyled";
import ButtonBorderStyled from "../components/ButtonBorderStyled";
import {styled} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";
import {GlobalContext} from "../context/GlobalContext";
import useTranslation from "../hooks/translations";
import useUI from "../hooks/useUI";

const DividerDashed = styled(Divider)(({theme}: {theme: any}) => ({
    width: "70%",
    border: "0 none",
    borderTop: "2px dashed #322f32",
    background: "none",
    height: 0,
    marginTop: theme.spacing(9.5),
    [theme.breakpoints.down("md")]: {
        marginTop: theme.spacing(3), width: "100%",
    },
}));

const ErrorPage = () => {
    const classes = useStyles();
    const {searchResults, errorRequest} = useContext(GlobalContext);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {isMobileMode} = useUI();

    const errorMessages: { [key: string]: string } = {
        "00020": t("mobileError"), "00004": t("paymentError"),
    };

    const errorMessage = errorRequest?.code ? errorMessages[errorRequest.code] : t("generalError");

    return (<Grid container item xl={9}>
            <Grid item xs={12} xl={10} md={8}>
                <Typography
                    variant={isMobileMode ? "h5" : "h1"}
                    component={"h1"}
                    className={classes.title}>
                    {t("errorPageTitle")}
                </Typography>
                <Typography
                    variant={isMobileMode ? "body2" : "subtitle1"}
                    component="p"
                    className={classes.subtitle}
                >
                    {t("errorPageSubtitle")}
                </Typography>
                {errorRequest?.code && (<Typography
                        variant={isMobileMode ? "body1" : "h6"}
                        component={"p"}
                        className={classes.errorCode}
                    >
                        {`CODE: ${errorRequest.code}`}
                    </Typography>)}
                <Typography
                    variant={isMobileMode ? "caption" : "h6"}
                    component={"p"}
                    className={classes.errorMessage}
                >{`Message: ${errorMessage}`}</Typography>
                <Grid
                    item
                    container
                    className={classes.buttonsContainer}
                >
                    <ButtonBorderStyled
                        onClick={() => navigate(-1)}
                        label={t("backButton")}
                        style={{minWidth: 132}}
                    />
                    <Link href={`${searchResults?.fullReturnUrl}`}>
                        <ButtonStyled
                            label={`${t("backToShopButton")} ${searchResults?.merchantInfo?.name}`}
                            style={{minWidth: 277}}
                        />
                    </Link>
                </Grid>
                <DividerDashed/>
                <Typography
                    variant={isMobileMode ? "body1" : "subtitle1"}
                    component={"p"}
                    className={classes.supportMessage}
                >
                    {t("contactSupport")}
                    <Typography component={"span"}>
                        <Link
                            href="mailto:support@cm.smobilpay.com"
                            underline="hover"
                        >
                            support@cm.smobilpay.com
                        </Link>
                    </Typography>
                </Typography>
            </Grid>
        </Grid>);
};

export default ErrorPage;

const useStyles = makeStyles((theme: any) => ({
    title: {
        paddingBottom: theme.spacing(3), [theme.breakpoints.down("md")]: {
            textAlign: "center", paddingBottom: theme.spacing(1.5),
        },
    }, errorCode: {
        paddingBottom: theme.spacing(5.5), [theme.breakpoints.down("md")]: {
            textAlign: "center", paddingBottom: theme.spacing(1.5),
        },
    }, errorMessage: {
        paddingBottom: theme.spacing(8.5), [theme.breakpoints.down("md")]: {
            textAlign: "center", paddingBottom: theme.spacing(3),
        },
    }, buttonsContainer: {
        justifyContent: "flex-start", [theme.breakpoints.down("md")]: {
            flexDirection: "column-reverse !important", rowGap: theme.spacing(1.5),
        },
    }, supportMessage: {
        paddingTop: theme.spacing(4), [theme.breakpoints.down("md")]: {
            paddingTop: theme.spacing(3), textAlign: "center",
        },
    }, subtitle: {
        paddingBottom: theme.spacing(5.5), [theme.breakpoints.down("md")]: {
            textAlign: "center", paddingBottom: theme.spacing(1.5),
        },
    },
}));