import React from "react";
import Flag from "./Flag";

interface Option {
  code: string;
}

interface AutocompleteCountryOptionProps {
  option: Option;
}

const getCountryNameFromCode = (code: string, locale: string = "en"): string | undefined => {
  if (!code) return;
  const regionNames = new Intl.DisplayNames([locale], { type: "region" });
  if (!regionNames) return code;
  return regionNames.of(code) ? regionNames.of(code) : code;
};

const AutocompleteCountryOption: React.FC<AutocompleteCountryOptionProps> = ({ option }) => {
  return (
    <>
      <Flag code={option?.code?.toLowerCase()} />
      <div
        style={{ display: "flex", justifyContent: "space-between", flex: 1 }}
      >
        <div>{getCountryNameFromCode(option?.code, "en")}</div>
      </div>
    </>
  );
};

export default AutocompleteCountryOption;
