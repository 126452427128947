import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useColumns} from "../../../../helpers/OrderColumns";
import CardGrid from "../../../../components/CardGrid";
import CardFooterRow from "../CardFooterRow";
import palette from "../../../../styles/palette";
import "./styles.css";
import useUI from "../../../../hooks/useUI";
interface OrdersCardDesktopProps {
    data: { currency: string };
    items: any[];
    amountsData: { name: string }[];
    theme: { secondaryColor?: string };
}


const OrdersCardDesktop: React.FC<OrdersCardDesktopProps> = ({ data, items, amountsData, theme }) => {
    const { isMobileMode } = useUI()
    const {webColumns, mobileColumns} = useColumns()
    
    return (
        <>
            <Grid className="gridWrapper">
                <CardGrid
                    rows={items}
                    columns={isMobileMode ? mobileColumns : webColumns}
                    currency={data?.currency}
                />
            </Grid>
            <Box className="footer" sx={{ backgroundColor: theme?.secondaryColor ? theme.secondaryColor : palette.background.grey }}>
                {amountsData.map((item) => (
                    <CardFooterRow
                        item={item}
                        rowHeight={45}
                        typographyVariant="t4"
                        lastItemTypographyVariant="b7"
                        key={item.name}
                    />
                ))}
            </Box>
        </>
    );
};

export default OrdersCardDesktop;
