import React from "react";
import { Box, FormControl, MenuItem, Select, SelectProps } from "@mui/material";

import "./style.css"
import useTranslation from "../../hooks/translations";

interface LanguageToggleProps extends Omit<SelectProps, 'defaultValue'> {
  language?: string;
  changeLanguage: (language: string) => void;
}

const LanguageToggle: React.FC<LanguageToggleProps> = ({ language, changeLanguage, ...props }) => {
  const {t} = useTranslation()
  return (
    <Box className={"languageBox"}>
      <FormControl className={"languageFormControl"}>
        <Select
          value={language}
          onChange={(e) => changeLanguage(e.target.value as string)}
          className={"languageSelect2"}
          {...props}
        >
          <MenuItem value="en">{t("english")}</MenuItem>
          <MenuItem value="fr">{t("french")}</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default LanguageToggle;
