import React, { useContext, useState } from "react";
import { Grid, Box } from "@mui/material";
import { ReactComponent as Logo } from "../../assets/images/logoDesktop.svg";
import PoweredBy from "../../assets/images/poweredBy.svg";

import { GlobalContext } from "../../context/GlobalContext";
import palette from "../../styles/palette";
import LanguageToggle from "../../components/LanguageToggle";
import "./style.css"

const HeaderDesktop = () => {
    const { theme, logo, setLanguage, language } = useContext(GlobalContext);
    return (
        <>
            <Grid className={"headerRoot"} sx={{ backgroundColor: theme?.headerColor ? theme.headerColor : palette.background.light }} position="static">
                <Grid
                    className={"container"}
                    item
                    xl={10}
                    md={12}
                    xs={12}
                >   
                    {logo ? <img className={"logo"} src={`data:image/png;base64,${logo}`} alt="Merchant Logo" />: <Logo className={"logo"} />}
                    <Box className={"rightPart"}>
                        <LanguageToggle language={language} changeLanguage={(language) => {setLanguage(language)}} />
                        <img src={PoweredBy} className={"poweredBy"} />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};
export default HeaderDesktop;
