export const handleOptionClick= (
  paymentProvider: any,
  { formik, setPaymentProviderId, setPricingId, setProviderType, setChange }: any
) => {
  console.log("PaymentProvider", paymentProvider)
  setChange(true)
  formik.setFieldValue("providerType", paymentProvider.name);
  setProviderType(paymentProvider.name);
  formik.setTouched({}, false);
  if (paymentProvider.name.toLowerCase().includes("paypal") || paymentProvider.name.toLowerCase().includes("card")) {
    const paymentProviderInfo =
      paymentProvider.paymentOptions[0].paymentProviderInfo;

    formik.setFieldValue("paymentProviderId", {
      uuid: paymentProviderInfo.id.uuid,
    });
    setPaymentProviderId({ uuid: paymentProviderInfo.id.uuid });
    formik.setFieldValue("customerPricingId", {
      uuid: paymentProviderInfo.customerPricingId.uuid,
    });
    setPricingId(paymentProviderInfo.customerPricingId.uuid);
  }
};
