import React from "react";
import { Alert as MuiAlert, AlertTitle, AlertProps as MuiAlertProps, AlertColor } from "@mui/material";

interface AlertProps extends MuiAlertProps {
  title: string;
  description: string;
  onClose: () => void;
  status?: AlertColor;
  variant?: "filled" | "outlined" | "standard";
  titleProps?: object;
}

const Alert: React.FC<AlertProps> = ({
  title,
  description,
  onClose,
  status = undefined,
  variant = "outlined",
  titleProps,
  ...rest
}) => {
  return (
    <MuiAlert
      severity={status}
      variant={variant}
      onClose={onClose}
      {...rest}
    >
      <AlertTitle sx={{ fontWeight: "bold" }} {...titleProps}>{title}</AlertTitle>
      {description}
    </MuiAlert>
  );
};

export default Alert;
