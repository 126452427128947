import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import ButtonStyled from "../../../../components/ButtonStyled";

import expressunion from "../../../../assets/images/expressunion.png";
import mtn from "../../../../assets/images/mtn.png";
import paypalIcon from "../../../../assets/images/paypal.png";
import orangeV2 from "../../../../assets/images/orangeV2.png";
import smobilpay from "../../../../assets/images/smobilpay.png";
import yoomee from "../../../../assets/images/yoomee.png";

import { countryList } from "../../../../constants";
import useTranslation from "../../../../hooks/translations";
import palette from "../../../../styles/palette";
import { CREDIT_CARD, MOBILE_MONEY, PAYPAL } from "../../../../constants/data";
import { handleOptionClick } from "../../../../utils/handleSelectChange";
import PaymentOption from "../../../../components/PaymentOption";
import NameSection from "./NameSection";
import PhoneSection from "./PhoneSection";
import ProviderSection from "./ProviderSection";
import CountrySection from "./CountrySection";
import EmailSection from "./EmailSection";
import { appConfig } from "../../../../config";

interface FormsProps {
  formik: any;
  payment: any;
  setPaymentProviderId: any;
  setPricingId: any;
  setPaymentProviderName: any;
  radioChecked: boolean;
  setRadioChecked: (checked: boolean) => void;
  paymentProviderName: string;
  checked: boolean;
  handleChecked: () => void;
  theme: any;
  providerType: string;
  setProviderType: (type: string) => void;
}

const Forms: React.FC<FormsProps> = ({
  formik,
  payment,
  setPaymentProviderId,
  setPricingId,
  setPaymentProviderName,
  radioChecked,
  setRadioChecked,
  paymentProviderName,
  checked,
  handleChecked,
  theme,
  providerType,
  setProviderType,
}) => {
  const { t } = useTranslation();

  const { isValidating, isValid } = formik;
  const isSubmitDisabled = isValidating || !isValid || !checked;
  const isPaypal = formik?.values?.providerType === PAYPAL;
  const isCard = formik?.values?.providerType === CREDIT_CARD;

  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [change, setChange] = useState(false);

  const defaultValue = countryList.find((item) => item?.name === "Cameroon");

  const onChangeCountry = (country: any) => {
    setSelectedOption(country);
  };

  const translatedPaymentOptions: any = {
    Paypal: t("paypal"),
    "Mobile Money": t("mobileMoney"),
    Card: t("creditCard"),
  };

  const terms = (
    <React.Fragment>
      <Typography>
        {t("agreeTo")}
        <Link
          underline="none"
          color="#4784F9"
          href="https://enkap.cm/privacy-policy/?lang=en"
        >
          {` ${t("termsAndCondition")}`}
        </Link>
      </Typography>
    </React.Fragment>
  );

  useEffect(() => {
    if (defaultValue && !selectedOption) {
      setSelectedOption(defaultValue);
    }
  }, [defaultValue, selectedOption]);

  return (
    <div className={"formSection"}>
      <Grid className={"formHeader"} container direction="column">
        <Typography variant="h6" style={{ fontWeight: 600, color: "#2E2E2E" }}>
          {t("paymentMethod")}
        </Typography>
      </Grid>
      <form onSubmit={formik.handleSubmit}>
        <Grid container direction="column" className={"rootForm"}>
          <Grid item>
            <FormControl className={"fieldset"} component="fieldset" fullWidth>
              {!change &&
                payment?.map((paymentProvider: any) => {
                  return (
                    appConfig.ACTIVATE_CREDIT_CARD &&
                    paymentProvider?.name !== CREDIT_CARD && (
                      <PaymentOption
                        key={paymentProvider.id}
                        paymentProvider={paymentProvider}
                        onClick={handleOptionClick}
                        optionChangeObject={{
                          formik,
                          setPaymentProviderId,
                          setPricingId,
                          setProviderType,
                          setChange,
                        }}
                      />
                    )
                  );
                })}
            </FormControl>
          </Grid>
          {change && providerType && (
            <>
              {/* Country */}
              <div className={"mobileMoneyHeader"}>
                <h2 className={"mobileMoneyHeaderTitle"}>
                  {translatedPaymentOptions[formik?.values?.providerType]}
                </h2>
                <button
                  className="changeButton"
                  onClick={() => setChange(!change)}
                >
                  {t("change")}
                </button>
              </div>
              {formik?.values?.providerType === MOBILE_MONEY && (
                <CountrySection
                  formik={formik}
                  onChangeCountry={onChangeCountry}
                  selectedOption={selectedOption}
                />
              )}

              {/* Providers */}
              {formik?.values?.providerType &&
                payment &&
                !isPaypal &&
                !isCard && (
                  <ProviderSection
                    payment={payment}
                    formik={formik}
                    providerType={providerType}
                    setPaymentProviderId={setPaymentProviderId}
                    setPricingId={setPricingId}
                    radioChecked={radioChecked}
                    setRadioChecked={setRadioChecked}
                    setPaymentProviderName={setPaymentProviderName}
                  />
                )}

              {/* Phone number */}
              {!isPaypal && (
                <>
                  <PhoneSection
                    formik={formik}
                    paymentProviderName={paymentProviderName}
                    disabled={isCard ? true : radioChecked}
                    providerType={providerType}
                  />
                  {/* Name and Surname */}
                  <NameSection formik={formik} />
                </>
              )}

              {/* Email Section */}
              {isCard && <EmailSection formik={formik} />}

              {/* Submit button */}
              {formik?.values?.providerType && (
                <Grid item className={"inputWrapper"} sx={{ marginTop: 2 }}>
                  {isPaypal && (
                    <Typography
                      style={{
                        fontSize: "0.85rem",
                      }}
                      mb={1}
                    >
                      {t("paypalRedirection")}
                    </Typography>
                  )}
                  {isCard && (
                    <Typography
                      style={{
                        fontSize: "0.85rem",
                      }}
                      mb={1}
                    >
                      {t("flocashRedirection")}
                    </Typography>
                  )}
                  <ButtonStyled
                    disabled={isSubmitDisabled}
                    type="submit"
                    label={
                      isPaypal ? "" : isCard ? t("proceed") : t("paymentButton")
                    }
                    icon={
                      isPaypal && <img src={paypalIcon} alt="paymentIcon" />
                    }
                    style={{
                      width: "100%",
                      height: 38,
                      backgroundColor: isSubmitDisabled
                        ? theme?.primaryColor
                        : theme?.secondaryColor
                        ? theme.secondaryColor
                        : isPaypal
                        ? palette.orange
                        : palette.primary.main,
                    }}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
        {change && providerType && (
          <Grid className={"checkBoxWrapper"} container direction="column">
            {!checked && (
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#d32f2f",
                  marginBottom: 4,
                }}
              >
                <WarningAmberRoundedIcon
                  sx={{ marginRight: 1, fontSize: 20 }}
                />
                <Typography
                  style={{
                    fontSize: "0.75rem",
                  }}
                >
                  {t("paymentTerms")}
                </Typography>
              </Box>
            )}

            <FormControlLabel
              className={"checkBox"}
              label={terms}
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleChecked}
                  sx={{
                    "&.Mui-checked": {
                      color: theme?.primaryColor
                        ? theme?.primaryColor
                        : palette.primary.main,
                    },
                  }}
                />
              }
            />
          </Grid>
        )}
      </form>
    </div>
  );
};
export default Forms;

const Label = ({ name, onClick }: { name: any; onClick: any }) => {
  let logo = [
    { logo: mtn, name: "mtn" },
    { logo: orangeV2, name: "orangeV2" },
    { logo: smobilpay, name: "smobilpay" },
    { logo: expressunion, name: "expressunion" },
    { logo: yoomee, name: "yoomee" },
  ];
  let image = logo.find((i) => (i.name === name ? i.logo : null));
  return <img onClick={onClick} src={image?.logo} alt={name} loading="lazy" />;
};
