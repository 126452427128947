import React, { useContext, useEffect } from "react";
import FormDetails from "./FormDetails";
import OrdersCard from "./OrdersCard";
import {
  Button,
  Grid,
  Typography,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { GlobalContext } from "../../context/GlobalContext";
import useUI from "../../hooks/useUI";
import ButtonStyled from "../../components/ButtonStyled";
import { searchResultsMock } from "../../constants/mock";
import { getIn } from "formik";

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: "100%",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      paddingRight: 0,
    },
  },
}));

const OrdersPage = () => {
  const classes = useStyles();
  let { searchResults, load, showPaymentMethods, setShowPaymentMethods } = useContext(GlobalContext);

  const theme: any = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("xs"));
  const { isMobileMode } = useUI();

  useEffect(() => {
    setShowPaymentMethods(isMobileMode ? false : true);
  }, [isMobileMode]);
  return !load ? (
    <Grid
      container
      item
      justifyContent="space-between"
      direction={matches ? "row" : "column"}
      sx={{ flexBasis: isMobileMode ? "100%" : "66.666667%" }}
    >
      {!isMobileMode && (
        <Grid
          item
          xl={6}
          xs={12}
          mb={6}
          lg={12}
          className={classes.root}
          order={{ xs: 1, sm: 1, xl: 1 }}
        >
          <OrdersCard data={searchResults?.orderPlacement} />
        </Grid>
      )}

      {isMobileMode ? (
        showPaymentMethods ? (
          <Grid
            item
            xl={5}
            xs={12}
            lg={12}
            className={classes.root}
            order={{ xs: 2, sm: 2, xl: 2 }}
          >
            <FormDetails
              payment={searchResults?.paymentProviderData}
              order={searchResults?.orderPlacement}
              merchant={searchResults?.merchantInfo}
            />
            <ButtonStyled
              type="submit"
              label={"Back"}
              style={{
                width: "100%",
                height: 38,
                backgroundColor: theme?.primaryColor,
                marginTop: 20,
              }}
              onClick={() => setShowPaymentMethods(false)}
            />
          </Grid>
        ) : (
          <Grid
            item
            xl={6}
            xs={12}
            mb={3}
            lg={12}
            className={classes.root}
            order={{ xs: 1, sm: 1, xl: 1 }}
          >
            <OrdersCard data={searchResults?.orderPlacement} />
            <ButtonStyled
              type="submit"
              label={"Continue to payment"}
              style={{
                width: "100%",
                height: 38,
                backgroundColor: theme?.primaryColor,
                marginTop: 20,
              }}
              onClick={() => setShowPaymentMethods(true)}
            />
          </Grid>
        )
      ) : (
        <Grid
          item
          xl={5}
          xs={12}
          lg={12}
          className={classes.root}
          order={{ xs: 2, sm: 2, xl: 2 }}
        >
          <FormDetails
            payment={searchResults?.paymentProviderData}
            order={searchResults?.orderPlacement}
            merchant={searchResults?.merchantInfo}
          />
        </Grid>
      )}
    </Grid>
  ) : (
    <Typography>Loading...</Typography>
  );
};

export default OrdersPage;