import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./phoneNumber.css";
import { getIn } from "formik";
import useTranslation from "../hooks/translations";

interface PhoneNumberInputProps {
  name: string;
  formik: any;
  defaultValue?: string;
  [key: string]: any;
}

export const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  name,
  formik,
  defaultValue,
  ...rest
}) => {
  const {t} = useTranslation();

  const onValueChange = (phoneNumber: string) => {
    return formik.setFieldValue(name, phoneNumber);
  };

  return (
    <PhoneInput
      {...rest}
      country={"cm"}
      autoFormat={true}
      masks={{ cm: ". .. .. .. .." }}
      placeholder="+237 e.g 6########"
      isValid={(value: string) => {
        if (value.length === 0) {
          return `${t("paymentPhoneError")}`;;
        }
        if (getIn(formik.errors, name)) {
          return `${getIn(formik.errors, name)}`;
        }
        return true
      }}
      preferredCountries={["cm"]}
      countryCodeEditable={false}
      value={getIn(formik?.values, name)}
      onChange={onValueChange}
      onBlur={formik?.handleBlur}
      containerStyle={{
        width: "100%",
        height: 38,
        borderRadius: 0,
        backgroundColor: "#ffffff",
        marginBottom: 15,
      }}
      inputStyle={{
        height: 38,
        width: "100%",
        fontFamily: "IBM Plex Sans, sans-serif",
        fontSize: "1rem",
        borderRadius: 3,
        backgroundColor: "#ffffff",
      }}
      buttonStyle={{
        height: 38,
        marginRight: 12,
        backgroundColor: "#ffffff",
      }}
      dropdownStyle={{
        borderRadius: 0,
        backgroundColor: "#ffffff",
      }}
    />
  );
};
