import React, { useContext } from "react";
import useUI from "../../hooks/useUI";
import HeaderDesktop from "./HeaderDesktop";
import { Box, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { Alert } from "../../components/material";
import { makeStyles } from "@mui/styles";
import { GlobalContext } from "../../context/GlobalContext";
import { theme } from "../../styles/theme";
import useTranslation from "../../hooks/translations";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: "30px",
  },
  header: {
    color: "#2E2E2E",
  },
}));

const Header = () => {
  const { isMobileMode } = useUI();
  const { pathname } = useLocation();
  let { showPaymentMethods, setShowPaymentMethods } = useContext(GlobalContext);
  const {t} = useTranslation()
  const showAlert = pathname === "/";

  const classes = useStyles({ isMobileMode });
  return (
    <>
      <HeaderDesktop />
      {showAlert && (
        <Box className={classes.container}>
          <Box
            style={{
              width: isMobileMode ? "92%" : "65.666667%",
              marginBottom: 5,
            }}
          >
            <Alert
              severity="info"
              style={{ fontSize: "16px" }}
              titleProps={{ style: { fontWeight: 400, marginTop: 0 } }}
              variant="standard"
              title={t("verifyWalletBalance")}
              description=""
              action={""}
              onClose={() => {}}
            />
            {(isMobileMode ? !showPaymentMethods : true) && (
              <Box
                sx={{
                  marginTop: isMobileMode ? 2 : 5,
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography variant="h2" sx={{color: "#2E2E2E", fontWeight: 600, fontSize: 24 }}>{t("shoppingCard")}</Typography>
                <Typography
                  sx={{
                    color: "#2E2E2E",
                    [theme.breakpoints.down('md')]: {
                      fontSize: 14
                    },
                    [theme.breakpoints.up('md')]: {
                      fontSize: 16
                    },
                  }}
                >
                  {t("doubleCheckSelectedItems")}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default Header;
