import React, {createContext, useEffect, useState} from 'react'
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { appConfig } from '../config';

export interface GlobalContextType {
  searchResults?: any
  errorRequest?: { code: string };
  load?: boolean;
  setLoad?: any,
  setSearchResults?: any,
  setErrorRequest?: any,
  language?: string,
  setLanguage: (lang: string) => any,
  paymentProviderName?: any,
  setPaymentProviderName?: any,
  setTheme?: any,
  theme?: any,
  logo?: any,
  showPaymentMethods?: any,
  setShowPaymentMethods?: any
}
export const GlobalContext = createContext<GlobalContextType>({setLanguage: (language) => language})
export const GlobalProvider = ({children}: {children: any}) => {

    const [searchResults, setSearchResults] = useState([]);
    const [load, setLoad] = useState(true);
    const [errorRequest, setErrorRequest] = useState({code: "", message: ""});
    const [language, setLanguage] = useState("en");
    const [paymentProviderName, setPaymentProviderName] = useState("");
    const [theme, setTheme] = useState()
    const [logo, setLogo] = useState()
    const navigate = useNavigate();
    const [showPaymentMethods, setShowPaymentMethods] = useState();

    useEffect(() => {
        setLoad(true)
        axios.defaults.withCredentials = true;
        axios({
            method: "GET",
            baseURL: appConfig.API_HOST,
            url: `/payment/api/ui/paymentDataInfo`,
            withCredentials: true
        })
            .then((resp) => {
                const allData = resp.data;
                setSearchResults(allData)
                setTheme(allData.merchantInfo.merchantColor);
                setLogo(allData.merchantInfo.merchantLogo.image);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    return navigate(`/unauthenticated`)
                }
            }).finally(() => setLoad(false))

    }, []);

    return (<GlobalContext.Provider
            value={{
                load,
                setLoad,
                searchResults,
                setSearchResults,
                errorRequest,
                setErrorRequest,
                language,
                setLanguage,
                paymentProviderName,
                setPaymentProviderName,
                setTheme,
                theme,
                logo,
                showPaymentMethods,
                setShowPaymentMethods
            }}
        >
            {children}
        </GlobalContext.Provider>
    )

}







