import useTranslation from "../hooks/translations";

export const useColumns = () => {
  const {t} = useTranslation();

  return {
    webColumns: [
      {
        id: "number",
        label: "N°",
        type: "string",
        align: "left",
      },
      {
        id: "description",
        label: t("product"),
        type: "string",
        align: "left",
      },
      {
        id: "qty",
        label: t("quantity"),
        type: "string",
        align: "center",
      },
      {
        id: "itemPrice",
        label: t("unitPrice"),
        type: "string",
        align: "center",
      },
      {
        id: "amount",
        label: t("totalPrice"),
        type: "string",
        align: "center",
      },
    ],

    mobileColumns: [
      {
        id: "number",
        label: "N°",
        type: "string",
        align: "left",
      },
      {
        id: "description",
        label: t("productMobile"),
        type: "string",
        align: "left",
      },
      {
        id: "qty",
        label: "Qty",
        type: "string",
        align: "center",
      },
      {
        id: "itemPrice",
        label: t("priceMobile"),
        type: "string",
        align: "center",
      },
      {
        id: "amount",
        label: "Total/FCFA",
        type: "string",
        align: "center",
      },
    ],
  };
};
