import React from "react";
import { Box, Typography, Modal } from "@mui/material";
import useUI from "../../hooks/useUI";
import "./style.css";
import Spinner from '../../assets/images/Spinner.png'
import useTranslation from "../../hooks/translations";

const keyframes = `
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

interface PaymentProcessingModalProps {
  amount: number;
  isOpen: boolean;
  onClose: () => void;
}

const PaymentProcessingModal: React.FC<PaymentProcessingModalProps> = ({
  amount,
  isOpen,
  onClose,
}) => {
  const { isMobileMode } = useUI();
  const {t, translationObject} = useTranslation()

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={"box"}>
        <Box className={"imageBox"}>
          <style>
            {keyframes}
          </style>
          <img src={Spinner} alt="processing" className={"spinnerStyle"} />
        </Box>
        <Typography variant="h4" component="h2" gutterBottom className={"centerText"}>
          {t("awaitingPayment")}
        </Typography>
        <Typography variant="body1" component="p" gutterBottom sx={{color: "#667085"}} className={"centerText"}>
        {t("notififcationToWithdraw")}
        </Typography>
        <Typography variant="h4" component="h2" gutterBottom sx={{fontWeight: 500, }} className={"centerText"}>
            {amount} FCFA
        </Typography>
        <Typography variant="body1" component="p" gutterBottom sx={{marginTop: 2}} className={"centerText"}>
            {translationObject.formatString(t("dialToApprove"), <span style={{color: "#0AA5B7", fontWeight: 600}}>*126# </span>)}
        </Typography>
      </Box>
    </Modal>
  );
};

export default PaymentProcessingModal;