import React from "react";
import { Grid, Typography } from "@mui/material";
import palette from "../../../styles/palette";


interface CardFooterRowProps {
    item: any;
    rowHeight?: number;
    typographyVariant?: any
    lastItemTypographyVariant?: any
}

const CardFooterRow: React.FC<CardFooterRowProps> = ({
    item,
    rowHeight = 30,
    typographyVariant = "t4",
    lastItemTypographyVariant = "b7",
}) => {
    return (
        <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{
                color: palette.black,
                height: rowHeight,
            }}
        >
            <Typography
                variant={
                    item.lastItem
                        ? lastItemTypographyVariant
                        : typographyVariant
                }
            >
                {item.name}
            </Typography>
            <Typography
                variant={
                    item.lastItem
                        ? lastItemTypographyVariant
                        : typographyVariant
                }
            >
                {item.value}
            </Typography>
        </Grid>
    );
};

export default CardFooterRow;
