import { useContext, useEffect, useState, useCallback } from "react";
import LocalizedStrings from "react-localization";
import localization from "../localization";
import { GlobalContext } from "../context/GlobalContext";

export default function useTranslation() {
  const { language } = useContext(GlobalContext);
  const [translation, setTranslation] = useState<any>(new LocalizedStrings(localization));

  useEffect(() => {
    const preferredLanguage = language || navigator.language || "en";
    const updatedTranslation = new LocalizedStrings(localization);
    updatedTranslation.setLanguage(preferredLanguage);
    setTranslation(updatedTranslation);
  }, [language]);

  // Function to get translated text based on a key
  const t = useCallback((key: string) => translation[key] || key, [translation]);

  return {t: t, translationObject: translation};
}
